.flex-row {
  flex-direction: row !important; }

.app {
    display: flex !important;
    flex-direction: column;
    min-height: 100%; }

.align-items-center {
      align-items: center !important; }

.mt-2 {
  margin-top: 20px !important;
}

.mt-3 {
  margin-top: 30px !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-3 {
  margin-bottom: 30px !important;
}

.ml-1 {
  margin-left: 10px !important;
}

.border-b-none {
  border-bottom: none !important;
}