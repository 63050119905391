.sidebar{
    &[data-color="black"]:after{
        @include icon-gradient(#282828, rgba(#111,.7));
    }
    .logo-img {
      text-align: center;
      width: 100%;
    }
    .logo-img img {
      max-width: 100px;
      // max-height: 40px;
    }
}

@media (max-width: 991px){
  .sidebar .nav > li.active.active-pro {
    position: relative;
    bottom: unset;
  }
}

#bodyClick {
  position: absolute;
}
